@import "./variables";

#content-area {
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
}

#filter {
  background: $grey;
  width: 30%;
  max-width: 300px;
  float: left;
  @media only screen and (max-width: 425px) {
    width: 40%;
  }
  .inside {
    padding: 20px;
    max-width: 300px;
  }
  h4 {
    font-size: 0.9rem;
    margin-bottom: 25px;
    text-transform: uppercase;
    font-weight: 700px;
  }
  select {
    background: transparent;
    padding: 10px;
    font-size: 14px;
    font-weight: 300;
    color: #1b1b1b;
    display: block;
    margin-bottom: 25px;
    width: 100%;
  }
  .filters {
    .title {
      font-size: 0.9rem;
      display: block;
      margin-bottom: 1rem;
      color: $darkgrey;
    }
    input[type="text"] {
      border-radius: 5px;
      display: inline-block;
      font-size: 1rem;
      padding: 0.4rem;
      width: 45%;
      margin-right: 2.5%;
      margin-bottom: 1rem;
      @media only screen and (max-width: 600px) {
        width: 90%;
      }
      @media only screen and (max-width: 425px) {
        width: 90%;
      }
      @media only screen and (max-width: 375px) {
        width: 95%;
      }
      @media only screen and (max-width: 325px) {
        width: 100%;
      }
    }
    &.extras {
      label {
        display: block;
        width: 100%;
        padding: 10px 0;
        input[type="checkbox"] {
          float: right;
          padding: 5rem 0;
        }
      }
    }
  }
}
