@import './variables';

#listings{
    width: 70%;
    display: inline-block;
    float: left;
    background: $offwhite;
    padding-left: 10px;
    @media only screen and (max-width: 425px) {
        width: 60%;
    }
    .search-area{
        input[type="text"] {
            width: 70%; 
            background: transparent;
            font-size: 2rem;
            padding: 15px;
            border: none;
            border-bottom: 1px solid $darkgrey;
            margin-bottom: 20px;
            font-weight: 100;
            color: $lightgrey;
        }
    }
    .sortby-area{
        margin-bottom: 20px;
        .results{
            font-size: .8rem;
            color: $darkgrey;
            display: inline-block;
        }
        .sort-options{
            float: right;
            select{
                font-size: .9rem;
                font-weight: 300;
                border: 1px solid $lightgrey;
                background: transparent;
                padding:20px;
                display: inline-block;
                margin-right: 15px;
                vertical-align: top;
            }
            .fa-th-list {
                font-size: 1.5rem;
            } 
            .view{
                display: inline-block;
                .fa{
                    display: inline-block;
                    font-size: 1.5rem;
                    margin-right: 10px;
                }
            }
        } 
    }
    .listings-results{
        margin-bottom: 60px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @media only screen and (min-width: 600px) {
            margin-top: 100px;
        }
        @media only screen and (min-width: 425px) {
            margin-top: 100px;
        }
        @media only screen and (max-width: 425px) {
            margin-top: 100px;
            width: 200px;
        }
        @media only screen and (max-width: 375px) {
            margin-top: 100px;
            width: 157px;
        }
        @media only screen and (max-width: 320px) {
            margin-top: 100px;
            width: 157px;
        }
        .listing{
            background: #fff;
            border-radius: 5px;
            -webkit-box-shadow: 14px 17px 43px -2px rgba(0,0,0,0.24);
            -moz-box-shadow: 14px 17px 43px -2px rgba(117, 19, 19, 0.24);
            box-shadow: 14px 17px 43px -2px rgba(0,0,0,0.24);
        }
        .no-results {
            text-align: center;
            margin: 0 auto;
            font-size: 3rem;
            @media only screen and (max-width: 425px) {
                font-size: 1.5rem;
                text-align: center;
                margin: 0 auto;
                padding-top: 3rem;
            }
            @media only screen and (max-width: 375px) {
                font-size: 1rem;
            }
        }
    }
    .listing-img{
        padding: 15px;
        color: white;
        position: relative;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        min-height: 200px;
        border-top-right-radius: 5px; 
        &:hover{
            .details{
                opacity: 1;
            }
            .address{
                opacity: 0;
            }
        }
        .address{
            font-size: 1.3rem;
            text-transform: capitalize;
            position: absolute;
            opacity: 1;
            transition: all .6s ease-in-out;
            bottom: 20px;
            text-shadow: 0px 1px 9px rgba(91,91,91,1);
        }
        .details{
            transition: all .6s ease-in-out;
            opacity: 0;
            padding: 4px;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(34, 31, 31,.8);
            border-top-right-radius: 5px; 
            border-top-left-radius: 5px; 
            .user-img{
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border: 2px solid white;
                display: inline-block;
            }
            .user-details{
                display: inline-block;
                vertical-align: top;
                .user-name{
                    font-size: .9rem;
                    font-weight: 300;
                    color: rgba(255,255,255,1);
                    display: block;
                    margin-top: 3px;
                    margin-bottom: 5px;
                }
                .post-date{
                    font-size: .9rem;
                    color: $lightgrey;
                    display: block;
                }
            }
            .listing-details{
                margin-top: 20px;
                .fa2{
                    display: inline-block;
                    font-size: 20px;
                    margin-right: 15px;
                }
                span{
                    display: inline-block;
                    font-size: .8rem;
                    font-weight: 300;
                    position: relative;
                    top: 3px;
                    vertical-align: top;
                }
                .floor-space{
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                    width: 200px;
                }
                .bedrooms{
                    display: flex;
                    flex-direction: row;
                    width: 200px;
                }
            }
            
        }
    }
    .bottom-info{
        padding: 20px 15px;
        .price{
            font-size: 1rem;
            display: block;
            color: $darkgrey;
        }
        .location{
            font-size: 1.2rem;
            display: block;
            margin-bottom: 10px;
            color: $lightgrey;
        }
    }
}

.view-btn{
    background: #FF718E;
    padding: 10px;
    font-size: .9rem;
    margin-top: 5px;
    border-radius: 2px;
    text-align: center;
    min-width: 130px;
    font-weight: 400;
    cursor: pointer;
    transition: background .6s ease-in-out;
    &:hover{
        background: #B28451;
        color: white;
    }
}

.pagination{
    text-align: center;
    .pages{
        display: inline-block;
        background: white;
        -webkit-box-shadow: 14px 17px 43px -2px rgba(0,0,0,0.24);
        -moz-box-shadow: 14px 17px 43px -2px rgba(117, 19, 19, 0.24);
        box-shadow: 14px 17px 43px -2px rgba(0,0,0,0.24);
        li{
            padding: 20px 30px;
            display: inline-block;
            &:hover,
            &.active {
                background: $pink;
                color: white;
                font-weight: 700;
                cursor: pointer;
            }
        }
    }
}


.col-md-3{
    width: 33.3%;
    display: inline-block;
    vertical-align: top;
    padding: 0 15px;
    padding-bottom: 5px;
    @media only screen and (max-width: 600px) {
        padding: 0;
    }
    @media only screen and (max-width: 425px) {
        padding: 0;
    }
}

.col-md-9{
    width: 25%;
    display: inline-block;
    .view-btn{
        background: #FF718E;
        padding: 5px;
        font-size: .9rem;
        margin-top: 2px;
        border-radius: 2px;
        text-align: center;
        min-width: 80px; 
        font-weight: 400;
        cursor: pointer;
        transition: all .4s ease-in-out;
        &:hover{
            background: rgb(252, 110, 139);
            color: #0C002B;
        }
    }
}


@media screen and (max-width: 992px) {
    .col-md-3 {
        width: 50%;
    }
  }
  
  @media screen and (max-width: 600px) {
    .col-md-3 {
        width: 95%;
    }
    .view-btn {
        width: 100px;
    }
    .filters{
        display: flex;
        flex-wrap: wrap;   
        flex-direction: column;
        width: 100%;
    }
  }


