@import "variables";
@import "https://fonts.googleapis.com/css?family=Roboto&display=swap";
@import "./_reset.scss";

*,
body,
html {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

@import "listings";
@import "Filter.scss";
