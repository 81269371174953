$darkpurple: #0c002b;

$grey: #faf7f5;

$offwhite: #fffeff;

$pink: #ff718e;

$darkgrey: #828d9d;

$lightgrey: #dadde2;

// COLOR VARIABLES
$color-primary: #c69963;
$color-primary-dark: #b28451;

$color-secondary: #101d2c;

$color-grey-light-1: #f9f7f6;
$color-grey-light-2: #aaa;

$color-grey-dark-1: #54483a;
$color-grey-dark-2: #6d5d4b;

// FONT VARIABLES
$font-primary: "Nunito", sans-serif;
$font-display: "Josefin Sans", sans-serif;

// RESPONSIVE BREAKPOINTS
$bp-largest: 75em; // 1200px
$bp-large: 62.5em; // 1000px
$bp-medium: 50em; // 800px;
$bp-small: 37.5em; // 600px;
