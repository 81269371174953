@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css?family=Aguafina+Script|Dancing+Script|Pacifico&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

.sidenav {
  font-family: "Lato", sans-serif;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: #101d2c;
  padding-top: 60px;
  overflow-x: hidden; }

.sidenav a {
  padding: 50px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #c69963;
  display: block;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

a.menu-list {
  padding-top: 50px;
  margin: 0;
  margin-left: 0;
  margin-right: 0; }
  @media screen and (min-width: 1800px) {
    a.menu-list {
      font-size: 2rem; } }

.menu-list__container {
  width: 100%;
  padding-left: 10%;
  padding-top: 25px; }
  @media screen and (min-width: 1800px) {
    .menu-list__container {
      padding-top: 3rem;
      max-width: 1280px; } }

.sidenav a:hover,
.offcanvas a:focus {
  color: whitesmoke;
  cursor: pointer; }

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 35px;
  font-size: 80px;
  margin-left: 50px;
  padding: 0px; }

.open {
  width: 100%; }

.close {
  width: 0; }

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px; }
  .sidenav a {
    font-size: 18px; } }

#header {
  background: #101d2c;
  color: white;
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-family: 'Roboto';
  height: 96px;
  align-items: center;
  display: flex;
  z-index: 200000; }
  #header .logo {
    height: 3rem;
    margin-left: 20px;
    width: 9rem; }
  #header nav {
    margin-right: 20px; }

.search::-webkit-input-placeholder {
  color: #dadde2;
  font-size: 1.8rem;
  font-family: "Nunito", sans-serif; }

.search::-moz-placeholder {
  color: #dadde2;
  font-size: 1.8rem;
  font-family: "Nunito", sans-serif; }

.search:-ms-input-placeholder {
  color: #dadde2;
  font-size: 1.8rem;
  font-family: "Nunito", sans-serif; }

.search::-ms-input-placeholder {
  color: #dadde2;
  font-size: 1.8rem;
  font-family: "Nunito", sans-serif; }

.search::placeholder {
  color: #dadde2;
  font-size: 1.8rem;
  font-family: "Nunito", sans-serif; }

#nav-icon1 {
  width: 60px;
  height: 45px;
  position: relative;
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer; }
  @media only screen and (max-width: 425px) {
    #nav-icon1 {
      margin: 0; } }

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

#nav-icon1 span:nth-child(1) {
  top: 0px;
  background: #c69963; }

#nav-icon1 span:nth-child(2) {
  top: 18px;
  background: #c69963; }

#nav-icon1 span:nth-child(3) {
  top: 36px;
  background: #c69963; }

#nav-icon1.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg); }

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px; }

#nav-icon1.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg); }

#content-area {
  overflow: hidden;
  width: 100%;
  min-height: 100vh; }

#filter {
  background: #faf7f5;
  width: 30%;
  max-width: 300px;
  float: left; }
  @media only screen and (max-width: 425px) {
    #filter {
      width: 40%; } }
  #filter .inside {
    padding: 20px;
    max-width: 300px; }
  #filter h4 {
    font-size: 0.9rem;
    margin-bottom: 25px;
    text-transform: uppercase;
    font-weight: 700px; }
  #filter select {
    background: transparent;
    padding: 10px;
    font-size: 14px;
    font-weight: 300;
    color: #1b1b1b;
    display: block;
    margin-bottom: 25px;
    width: 100%; }
  #filter .filters .title {
    font-size: 0.9rem;
    display: block;
    margin-bottom: 1rem;
    color: #828d9d; }
  #filter .filters input[type="text"] {
    border-radius: 5px;
    display: inline-block;
    font-size: 1rem;
    padding: 0.4rem;
    width: 45%;
    margin-right: 2.5%;
    margin-bottom: 1rem; }
    @media only screen and (max-width: 600px) {
      #filter .filters input[type="text"] {
        width: 90%; } }
    @media only screen and (max-width: 425px) {
      #filter .filters input[type="text"] {
        width: 90%; } }
    @media only screen and (max-width: 375px) {
      #filter .filters input[type="text"] {
        width: 95%; } }
    @media only screen and (max-width: 325px) {
      #filter .filters input[type="text"] {
        width: 100%; } }
  #filter .filters.extras label {
    display: block;
    width: 100%;
    padding: 10px 0; }
    #filter .filters.extras label input[type="checkbox"] {
      float: right;
      padding: 5rem 0; }

#listings {
  width: 70%;
  display: inline-block;
  float: left;
  background: #fffeff;
  padding-left: 10px; }
  @media only screen and (max-width: 425px) {
    #listings {
      width: 60%; } }
  #listings .search-area input[type="text"] {
    width: 70%;
    background: transparent;
    font-size: 2rem;
    padding: 15px;
    border: none;
    border-bottom: 1px solid #828d9d;
    margin-bottom: 20px;
    font-weight: 100;
    color: #dadde2; }
  #listings .sortby-area {
    margin-bottom: 20px; }
    #listings .sortby-area .results {
      font-size: .8rem;
      color: #828d9d;
      display: inline-block; }
    #listings .sortby-area .sort-options {
      float: right; }
      #listings .sortby-area .sort-options select {
        font-size: .9rem;
        font-weight: 300;
        border: 1px solid #dadde2;
        background: transparent;
        padding: 20px;
        display: inline-block;
        margin-right: 15px;
        vertical-align: top; }
      #listings .sortby-area .sort-options .fa-th-list {
        font-size: 1.5rem; }
      #listings .sortby-area .sort-options .view {
        display: inline-block; }
        #listings .sortby-area .sort-options .view .fa {
          display: inline-block;
          font-size: 1.5rem;
          margin-right: 10px; }
  #listings .listings-results {
    margin-bottom: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    @media only screen and (min-width: 600px) {
      #listings .listings-results {
        margin-top: 100px; } }
    @media only screen and (min-width: 425px) {
      #listings .listings-results {
        margin-top: 100px; } }
    @media only screen and (max-width: 425px) {
      #listings .listings-results {
        margin-top: 100px;
        width: 200px; } }
    @media only screen and (max-width: 375px) {
      #listings .listings-results {
        margin-top: 100px;
        width: 157px; } }
    @media only screen and (max-width: 320px) {
      #listings .listings-results {
        margin-top: 100px;
        width: 157px; } }
    #listings .listings-results .listing {
      background: #fff;
      border-radius: 5px;
      box-shadow: 14px 17px 43px -2px rgba(0, 0, 0, 0.24); }
    #listings .listings-results .no-results {
      text-align: center;
      margin: 0 auto;
      font-size: 3rem; }
      @media only screen and (max-width: 425px) {
        #listings .listings-results .no-results {
          font-size: 1.5rem;
          text-align: center;
          margin: 0 auto;
          padding-top: 3rem; } }
      @media only screen and (max-width: 375px) {
        #listings .listings-results .no-results {
          font-size: 1rem; } }
  #listings .listing-img {
    padding: 15px;
    color: white;
    position: relative;
    background-size: cover;
    min-height: 200px;
    border-top-right-radius: 5px; }
    #listings .listing-img:hover .details {
      opacity: 1; }
    #listings .listing-img:hover .address {
      opacity: 0; }
    #listings .listing-img .address {
      font-size: 1.3rem;
      text-transform: capitalize;
      position: absolute;
      opacity: 1;
      -webkit-transition: all .6s ease-in-out;
      transition: all .6s ease-in-out;
      bottom: 20px;
      text-shadow: 0px 1px 9px #5b5b5b; }
    #listings .listing-img .details {
      -webkit-transition: all .6s ease-in-out;
      transition: all .6s ease-in-out;
      opacity: 0;
      padding: 4px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(34, 31, 31, 0.8);
      border-top-right-radius: 5px;
      border-top-left-radius: 5px; }
      #listings .listing-img .details .user-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 2px solid white;
        display: inline-block; }
      #listings .listing-img .details .user-details {
        display: inline-block;
        vertical-align: top; }
        #listings .listing-img .details .user-details .user-name {
          font-size: .9rem;
          font-weight: 300;
          color: white;
          display: block;
          margin-top: 3px;
          margin-bottom: 5px; }
        #listings .listing-img .details .user-details .post-date {
          font-size: .9rem;
          color: #dadde2;
          display: block; }
      #listings .listing-img .details .listing-details {
        margin-top: 20px; }
        #listings .listing-img .details .listing-details .fa2 {
          display: inline-block;
          font-size: 20px;
          margin-right: 15px; }
        #listings .listing-img .details .listing-details span {
          display: inline-block;
          font-size: .8rem;
          font-weight: 300;
          position: relative;
          top: 3px;
          vertical-align: top; }
        #listings .listing-img .details .listing-details .floor-space {
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          width: 200px; }
        #listings .listing-img .details .listing-details .bedrooms {
          display: flex;
          flex-direction: row;
          width: 200px; }
  #listings .bottom-info {
    padding: 20px 15px; }
    #listings .bottom-info .price {
      font-size: 1rem;
      display: block;
      color: #828d9d; }
    #listings .bottom-info .location {
      font-size: 1.2rem;
      display: block;
      margin-bottom: 10px;
      color: #dadde2; }

.view-btn {
  background: #FF718E;
  padding: 10px;
  font-size: .9rem;
  margin-top: 5px;
  border-radius: 2px;
  text-align: center;
  min-width: 130px;
  font-weight: 400;
  cursor: pointer;
  -webkit-transition: background .6s ease-in-out;
  transition: background .6s ease-in-out; }
  .view-btn:hover {
    background: #B28451;
    color: white; }

.pagination {
  text-align: center; }
  .pagination .pages {
    display: inline-block;
    background: white;
    box-shadow: 14px 17px 43px -2px rgba(0, 0, 0, 0.24); }
    .pagination .pages li {
      padding: 20px 30px;
      display: inline-block; }
      .pagination .pages li:hover, .pagination .pages li.active {
        background: #ff718e;
        color: white;
        font-weight: 700;
        cursor: pointer; }

.col-md-3 {
  width: 33.3%;
  display: inline-block;
  vertical-align: top;
  padding: 0 15px;
  padding-bottom: 5px; }
  @media only screen and (max-width: 600px) {
    .col-md-3 {
      padding: 0; } }
  @media only screen and (max-width: 425px) {
    .col-md-3 {
      padding: 0; } }

.col-md-9 {
  width: 25%;
  display: inline-block; }
  .col-md-9 .view-btn {
    background: #FF718E;
    padding: 5px;
    font-size: .9rem;
    margin-top: 2px;
    border-radius: 2px;
    text-align: center;
    min-width: 80px;
    font-weight: 400;
    cursor: pointer;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out; }
    .col-md-9 .view-btn:hover {
      background: #fc6e8b;
      color: #0C002B; }

@media screen and (max-width: 992px) {
  .col-md-3 {
    width: 50%; } }

@media screen and (max-width: 600px) {
  .col-md-3 {
    width: 95%; }
  .view-btn {
    width: 100px; }
  .filters {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%; } }

.homes {
  grid-column: center-start / center-end;
  margin: 15rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 7rem;
  padding: 0;
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  margin: 1rem;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  justify-self: center;
  z-index: 100;
  width: 450px;
  height: 500px; }

.svg-container {
  padding: 10px;
  position: absolute;
  right: 40px;
  top: 40px; }
  .svg-container:hover {
    cursor: pointer;
    border-top: 1px solid #51cbee;
    border-left: 1px solid #51cbee; }
  @media only screen and (max-width: 600px) {
    .svg-container {
      right: 5%;
      top: 2%; } }

i.icon.heart {
  color: #c69963;
  width: 2rem;
  margin-right: 1rem;
  outline: none;
  font-size: 1rem; }

.home {
  background: #f9f7f6;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 3.5rem; }
  .home__img {
    width: 100%;
    grid-row: 1 / 2;
    grid-column: 1 / -1;
    z-index: 1; }
  .home__like {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    fill: #c69963;
    height: 2.5rem;
    width: 2.5rem;
    z-index: 2;
    justify-self: end;
    margin: 1rem; }
  .home__name {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
    justify-self: center;
    align-self: end;
    z-index: 3;
    width: 80%;
    font-family: "Josefin Sans", sans-serif;
    font-size: 0.8rem;
    text-align: center;
    padding: 1.25rem;
    background-color: #101d2c;
    color: #fff;
    font-weight: 400;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
    /*
      @media only screen and (min-width: 1800px) {
        font-size: 5rem;
      }*/ }
  .home__location, .home__rooms {
    margin-top: 2.5rem; }
  .home__location, .home__rooms, .home__area, .home__price {
    font-size: 0.8rem;
    margin-left: 2rem;
    display: flex;
    align-items: center; }
    .home__location i.icon,
    .home__location i.icons,
    .home__location i.icon.heart, .home__rooms i.icon,
    .home__rooms i.icons,
    .home__rooms i.icon.heart, .home__area i.icon,
    .home__area i.icons,
    .home__area i.icon.heart, .home__price i.icon,
    .home__price i.icons,
    .home__price i.icon.heart {
      color: #c69963;
      width: 2rem;
      margin-right: 1rem;
      outline: none;
      font-size: 1rem; }
  .home .home-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100;
    height: 100vh; }
  .home__btn {
    grid-column: 1 / -1;
    background-color: #c69963;
    color: #fff;
    border: none;
    border-radius: 0;
    font-family: "Josefin Sans", sans-serif;
    font-size: 1.5rem;
    text-transform: uppercase;
    padding: 1.8rem 3rem;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    font-weight: 300;
    line-height: 1.6;
    font-size: 62.5%;
    box-shadow: 0 0 5px #51cbee; }
    .home__btn .contact {
      margin-left: 35%; }
    .home__btn:hover {
      background-color: #b28451;
      font-size: 62.5%; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

@import https://fonts.googleapis.com/css?family=Roboto&display=swap;
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

*,
body,
html {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box; }

#header {
  background: #101d2c;
  color: white;
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-family: 'Roboto';
  height: 96px;
  align-items: center;
  display: flex;
  z-index: 200000; }
  #header .logo {
    height: 3rem;
    margin-left: 20px;
    width: 9rem; }
  #header nav {
    margin-right: 20px; }

.search::-webkit-input-placeholder {
  color: #dadde2;
  font-size: 1.8rem;
  font-family: "Nunito", sans-serif; }

.search::-moz-placeholder {
  color: #dadde2;
  font-size: 1.8rem;
  font-family: "Nunito", sans-serif; }

.search:-ms-input-placeholder {
  color: #dadde2;
  font-size: 1.8rem;
  font-family: "Nunito", sans-serif; }

.search::-ms-input-placeholder {
  color: #dadde2;
  font-size: 1.8rem;
  font-family: "Nunito", sans-serif; }

.search::placeholder {
  color: #dadde2;
  font-size: 1.8rem;
  font-family: "Nunito", sans-serif; }

#nav-icon1 {
  width: 60px;
  height: 45px;
  position: relative;
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer; }
  @media only screen and (max-width: 425px) {
    #nav-icon1 {
      margin: 0; } }

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

#nav-icon1 span:nth-child(1) {
  top: 0px;
  background: #c69963; }

#nav-icon1 span:nth-child(2) {
  top: 18px;
  background: #c69963; }

#nav-icon1 span:nth-child(3) {
  top: 36px;
  background: #c69963; }

#nav-icon1.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg); }

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px; }

#nav-icon1.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg); }

#content-area {
  overflow: hidden;
  width: 100%;
  min-height: 100vh; }

#filter {
  background: #faf7f5;
  width: 30%;
  max-width: 300px;
  float: left; }
  @media only screen and (max-width: 425px) {
    #filter {
      width: 40%; } }
  #filter .inside {
    padding: 20px;
    max-width: 300px; }
  #filter h4 {
    font-size: 0.9rem;
    margin-bottom: 25px;
    text-transform: uppercase;
    font-weight: 700px; }
  #filter select {
    background: transparent;
    padding: 10px;
    font-size: 14px;
    font-weight: 300;
    color: #1b1b1b;
    display: block;
    margin-bottom: 25px;
    width: 100%; }
  #filter .filters .title {
    font-size: 0.9rem;
    display: block;
    margin-bottom: 1rem;
    color: #828d9d; }
  #filter .filters input[type="text"] {
    border-radius: 5px;
    display: inline-block;
    font-size: 1rem;
    padding: 0.4rem;
    width: 45%;
    margin-right: 2.5%;
    margin-bottom: 1rem; }
    @media only screen and (max-width: 600px) {
      #filter .filters input[type="text"] {
        width: 90%; } }
    @media only screen and (max-width: 425px) {
      #filter .filters input[type="text"] {
        width: 90%; } }
    @media only screen and (max-width: 375px) {
      #filter .filters input[type="text"] {
        width: 95%; } }
    @media only screen and (max-width: 325px) {
      #filter .filters input[type="text"] {
        width: 100%; } }
  #filter .filters.extras label {
    display: block;
    width: 100%;
    padding: 10px 0; }
    #filter .filters.extras label input[type="checkbox"] {
      float: right;
      padding: 5rem 0; }

.about-us .form {
  display: flex;
  padding-top: 4rem;
  max-width: 1500px;
  margin: 0 auto;
  margin-bottom: 7rem; }
  @media only screen and (max-width: 850px) {
    .about-us .form {
      flex-direction: column; } }
  .about-us .form .contact-form {
    width: 60%;
    padding: 2rem; }
    .about-us .form .contact-form ::-webkit-input-placeholder {
      color: #777; }
    .about-us .form .contact-form :-moz-placeholder {
      /* Firefox 18- */
      color: #777; }
    .about-us .form .contact-form ::-moz-placeholder {
      /* Firefox 19+ */
      color: #777; }
    .about-us .form .contact-form :-ms-input-placeholder {
      color: #777; }
    @media only screen and (max-width: 850px) {
      .about-us .form .contact-form {
        width: 100%; } }
    .about-us .form .contact-form .get-in-touch {
      padding-left: 1rem;
      font-size: 4rem;
      padding-bottom: 2rem; }
      @media only screen and (max-width: 850px) {
        .about-us .form .contact-form .get-in-touch {
          font-size: 3.6rem;
          text-align: center; } }
      @media only screen and (max-width: 450px) {
        .about-us .form .contact-form .get-in-touch {
          padding-left: 0px; } }
    .about-us .form .contact-form .first-container {
      position: relative;
      width: 100%;
      padding: 1em;
      margin: auto;
      margin-top: 35px; }
    .about-us .form .contact-form .first-container h2 {
      text-align: center;
      text-transform: uppercase;
      color: #f55;
      justify-content: flex-end;
      display: flex; }
    .about-us .form .contact-form #contact input[type="text"], .about-us .form .contact-form #contact input[type="email"], .about-us .form .contact-form #contact textarea {
      display: block;
      padding: 10px;
      margin: 10px auto;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      background-color: #f6f6f6;
      border: none;
      border-radius: 5px 5px 5px 5px;
      -webkit-transition: all .2s ease-in;
      transition: all .2s ease-in; }
    .about-us .form .contact-form #contact textarea {
      font-size: 16px; }
    .about-us .form .contact-form #contact input:hover, .about-us .form .contact-form #contact textarea:hover {
      border-bottom: 2px solid #5fbae9;
      background: white; }
    .about-us .form .contact-form #contact input:focus, .about-us .form .contact-form #contact textarea:focus {
      outline: none;
      background: white;
      border-bottom: 2px solid #5fbae9; }
    .about-us .form .contact-form #contact textarea {
      max-width: 100%;
      min-width: 100%;
      max-height: 180px;
      min-height: 180px;
      resize: none; }
    .about-us .form .contact-form #contact .send-button {
      text-align: center;
      width: 169px;
      padding-top: 1rem;
      font-size: 12px; }
    .about-us .form .contact-form #contact .send-button button[type="submit"] {
      color: #fff;
      background: #e74c3c;
      border: none;
      width: 100%;
      padding: 10px 0;
      font-size: 20px;
      text-transform: uppercase;
      -webkit-transition: .5s all ease;
      transition: .5s all ease;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
      border-radius: 3px;
      cursor: pointer; }
    .about-us .form .contact-form .required-fields {
      font-size: 12px; }
    .about-us .form .contact-form #contact .send-button button[type="submit"]:hover {
      background: #c0392b; }
    .about-us .form .contact-form #contact .send-button button[type="submit"]:focus {
      position: relative;
      top: 2px; }
    .about-us .form .contact-form .line {
      height: 0.5px;
      width: 100%;
      background: #F1F1F1;
      margin: 0 auto;
      display: block; }
    @media screen and (min-width: 640px) {
      .about-us .form .contact-form .container {
        width: 600px; }
      .about-us .form .contact-form #contact textarea {
        min-height: 152px;
        margin-right: 0; }
      .about-us .form .contact-form #contact .right, .about-us .form .contact-form #contact .left {
        display: block; }
      .about-us .form .contact-form #contact .right {
        width: 100%; }
      .about-us .form .contact-form #contact .left {
        display: flex;
        justify-content: space-between; }
        .about-us .form .contact-form #contact .left div {
          width: 47%; }
      .about-us .form .contact-form .cl {
        clear: both; } }
  .about-us .form .contact-form-info {
    padding: 2rem;
    line-height: 1.8; }
    .about-us .form .contact-form-info .social-container {
      text-align: center;
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
      margin-bottom: 23px; }
      @media only screen and (max-width: 850px) {
        .about-us .form .contact-form-info .social-container {
          flex-direction: column; } }
    .about-us .form .contact-form-info .top-title {
      margin-top: 39px; }
    .about-us .form .contact-form-info h3, .about-us .form .contact-form-info .top-title {
      font-size: 2rem; }
    .about-us .form .contact-form-info p {
      color: #808080;
      color: #808080;
      font-weight: 100;
      font-size: 15px; }
    .about-us .form .contact-form-info #line {
      height: 1px;
      width: 100%;
      background: #F1F1F1;
      margin: 0 auto;
      display: block; }
    .about-us .form .contact-form-info .line {
      height: 0.5px;
      width: 100%;
      background: #F1F1F1;
      margin: 0 auto;
      display: block; }
    .about-us .form .contact-form-info .social-icons {
      list-style: none; }
      .about-us .form .contact-form-info .social-icons li {
        display: inline-block;
        margin: 0.15em;
        position: relative;
        font-size: 1.2em;
        margin-left: 12px; }
      .about-us .form .contact-form-info .social-icons i {
        color: #fff;
        position: absolute;
        top: 12px;
        left: 11px;
        -webkit-transition: all 265ms ease-out;
        transition: all 265ms ease-out; }
      .about-us .form .contact-form-info .social-icons a {
        display: inline-block; }
        .about-us .form .contact-form-info .social-icons a:before {
          transform: scale(1);
          -ms-transform: scale(1);
          -webkit-transform: scale(1);
          content: " ";
          width: 40px;
          height: 40px;
          border-radius: 100%;
          display: block;
          background: -webkit-linear-gradient(45deg, #ff003c, #c648c8);
          background: linear-gradient(45deg, #ff003c, #c648c8);
          -webkit-transition: all 265ms ease-out;
          transition: all 265ms ease-out; }
        .about-us .form .contact-form-info .social-icons a:hover:before {
          -webkit-transform: scale(0);
                  transform: scale(0);
          -webkit-transition: all 265ms ease-in;
          transition: all 265ms ease-in; }
        .about-us .form .contact-form-info .social-icons a:hover i {
          transform: scale(2.2);
          -ms-transform: scale(2.2);
          -webkit-transform: scale(2.2);
          color: #ff003c;
          background: -webkit-linear-gradient(45deg, #ff003c, #c648c8);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -webkit-transition: all 265ms ease-in;
          transition: all 265ms ease-in; }
      .about-us .form .contact-form-info .social-icons a.social-square:before {
        background: -webkit-linear-gradient(45deg, #7b00e0, #ae31d9);
        background: linear-gradient(45deg, #7b00e0, #ae31d9);
        border-radius: 10%; }
      .about-us .form .contact-form-info .social-icons a.social-square:hover:before {
        transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -webkit-transform: scale(-180deg);
        border-radius: 100%; }
      .about-us .form .contact-form-info .social-icons a.social-square:hover i {
        transform: scale(1.6);
        -ms-transform: scale(1.6);
        -webkit-transform: scale(1.6);
        color: #fff;
        transform: scale(1.6);
        -webkit-text-fill-color: #fff; }

.about-us .map {
  height: 400px;
  margin-bottom: 7rem; }
  @media only screen and (max-width: 650px) {
    .about-us .map {
      margin-bottom: 3rem; } }

.about-us .contact {
  display: flex;
  flex-direction: row;
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  line-height: 2;
  max-width: 1000px;
  justify-content: space-between;
  margin-bottom: 5rem; }
  @media only screen and (max-width: 1100px) {
    .about-us .contact {
      padding: 2rem; } }
  @media only screen and (max-width: 850px) {
    .about-us .contact {
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: 600px; } }
  @media only screen and (max-width: 650px) {
    .about-us .contact {
      width: 100%;
      flex-direction: column; } }
  @media only screen and (max-width: 850px) {
    .about-us .contact .contact-info {
      width: 50%;
      padding-bottom: 2rem; } }
  .about-us .contact .contact-info h2 {
    color: #808080;
    font-weight: 100;
    letter-spacing: 2px; }
  .about-us .contact .contact-info h1 {
    letter-spacing: 1px;
    font-size: 14px; }

.about-us footer {
  background-color: #f1f2f6;
  border-style: solid;
  border-width: 40px 40px 40px;
  border-color: #f1f2f6;
  -webkit-border-image: url("http://news.unchealthcare.org/images/backgrounds/paper.jpg") 40 40 40 repeat;
  border-image: url("http://news.unchealthcare.org/images/backgrounds/paper.jpg") 40 40 40 repeat;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem; }
  @media only screen and (max-width: 1100px) {
    .about-us footer {
      padding-top: 4rem; } }
  @media only screen and (max-width: 860px) {
    .about-us footer {
      display: flex;
      flex-direction: column; } }
  .about-us footer .social-media {
    display: flex;
    width: 437px;
    justify-content: space-between; }
    @media only screen and (max-width: 860px) {
      .about-us footer .social-media {
        margin: 0 auto;
        padding-bottom: 24px; } }
    @media only screen and (max-width: 530px) {
      .about-us footer .social-media {
        display: none; } }
    .about-us footer .social-media i {
      color: #e74c3c;
      padding-right: 8px; }
    .about-us footer .social-media span {
      color: #495057;
      font-size: 15px; }
  .about-us footer .all-rights {
    font-size: 13px;
    line-height: 1.4;
    color: #495057; }
    @media only screen and (max-width: 860px) {
      .about-us footer .all-rights {
        margin: 0 auto;
        line-height: 3; } }

.fa-twitter {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

.fa-linkedin {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }

.fa-codepen {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s; }

.alert {
  width: 440px;
  border-radius: 6px;
  margin: 20px auto;
  padding: auto 0;
  position: fixed;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 9999;
  color: #fff;
  text-align: center;
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.25); }
  .alert i {
    vertical-align: middle;
    padding: 20px; }
    .alert i.exit-button {
      float: right;
      opacity: .4; }
      .alert i.exit-button:hover {
        opacity: .8; }

.message-text {
  vertical-align: middle; }

.error {
  background-color: #ECC8C5;
  border: #d37f78 2px solid;
  color: #B83C37; }
  .error .fa-check {
    visibility: hidden; }

.success {
  background-color: #DDF3D5;
  border: #9ddc86 2px solid;
  color: #597151; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

.container {
  box-sizing: border-box;
  font-size: 62.5%;
  display: grid;
  grid-template-rows: 80vh -webkit-min-content 40vw repeat(3, -webkit-min-content);
  grid-template-rows: 80vh min-content 40vw repeat(3, min-content);
  grid-template-columns: [sidebar-start] 8rem [sidebar-end full-start] minmax(6rem, 1fr) [center-start] repeat(8, [col-start] minmax(-webkit-min-content, 14rem) [col-end]) [center-end] minmax(6rem, 1fr) [full-end];
  grid-template-columns: [sidebar-start] 8rem [sidebar-end full-start] minmax(6rem, 1fr) [center-start] repeat(8, [col-start] minmax(min-content, 14rem) [col-end]) [center-end] minmax(6rem, 1fr) [full-end];
  font-family: "Nunito", sans-serif;
  color: #6d5d4b;
  font-weight: 300;
  line-height: 1.6; }
  @media only screen and (max-width: 62.5em) {
    .container {
      grid-template-rows: 6rem 80vh -webkit-min-content 40vw repeat(3, -webkit-min-content);
      grid-template-rows: 6rem 80vh min-content 40vw repeat(3, min-content);
      grid-template-columns: [full-start] minmax(6rem, 1fr) [center-start] repeat(8, [col-start] minmax(-webkit-min-content, 14rem) [col-end]) [center-end] minmax(6rem, 1fr) [full-end];
      grid-template-columns: [full-start] minmax(6rem, 1fr) [center-start] repeat(8, [col-start] minmax(min-content, 14rem) [col-end]) [center-end] minmax(6rem, 1fr) [full-end]; } }
  @media only screen and (max-width: 50em) {
    .container {
      grid-template-rows: 6rem calc(100vh - 6rem); } }

.features {
  grid-column: center-start / center-end;
  margin: 15rem 0; }
  @supports (display: grid) {
    .features {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
      grid-gap: 6rem;
      align-items: start; } }

.feature {
  float: left;
  width: 33.3333%;
  margin-bottom: 6rem;
  display: grid;
  grid-template-columns: -webkit-min-content 1fr;
  grid-template-columns: min-content 1fr;
  grid-row-gap: 1.5rem;
  grid-column-gap: 2.5rem; }
  @supports (display: grid) {
    .feature {
      width: auto;
      margin-bottom: 0; } }
  .feature__icon {
    fill: #c69963;
    width: 4.5rem;
    height: 4.5rem;
    grid-row: 1 / span 2;
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem); }
  .feature__text {
    font-size: 1.7rem; }

.footer {
  background-color: #101d2c;
  grid-column: full-start / full-end;
  padding: 8rem; }
  @media only screen and (max-width: 425px) {
    .footer {
      padding: 0; } }

.nav {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 2rem;
  align-items: center; }
  .nav__link:link, .nav__link:visited {
    font-size: 1.4rem;
    color: #fff;
    text-decoration: none;
    font-family: "Josefin Sans", sans-serif;
    text-transform: uppercase;
    text-align: center;
    padding: 1.5rem;
    display: block;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    font-size: 73%; }
  .nav__link:hover, .nav__link:active {
    background-color: rgba(255, 255, 255, 0.05);
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px); }

.copyright {
  font-size: 0.8rem;
  color: #aaa;
  margin-top: 6rem;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  width: 70%; }

.gallery {
  background-color: #f9f7f6;
  grid-column: full-start / full-end;
  display: grid;
  grid-template: repeat(7, 5vw)/repeat(8, 1fr);
  grid-gap: 1.5rem;
  padding: 1.5rem; }
  .gallery__item--1 {
    grid-row: 1 / span 2;
    grid-column: 1 / span 2; }
  .gallery__item--2 {
    grid-row: 1 / span 3;
    grid-column: 3 / span 3; }
  .gallery__item--3 {
    grid-row: 1 / span 2;
    grid-column: 6 / 7; }
  .gallery__item--4 {
    grid-row: 1 / span 2;
    grid-column: 7 / -1; }
  .gallery__item--5 {
    grid-row: 3 / span 3;
    grid-column: 1 / span 2; }
  .gallery__item--6 {
    grid-row: 4 / span 2;
    grid-column: 3 / span 2; }
  .gallery__item--7 {
    grid-row: 4 / 5;
    grid-column: 5 / 6; }
  .gallery__item--8 {
    grid-row: 3 / span 2;
    grid-column: 6 / span 2; }
  .gallery__item--9 {
    grid-row: 3 / span 3;
    grid-column: 8 / -1; }
  .gallery__item--10 {
    grid-row: 6 / span 2;
    grid-column: 1 / 2; }
  .gallery__item--11 {
    grid-row: 6 / span 2;
    grid-column: 2 / span 2; }
  .gallery__item--12 {
    grid-row: 6 / span 2;
    grid-column: 4 / 5; }
  .gallery__item--13 {
    grid-row: 5 / span 3;
    grid-column: 5 / span 3; }
  .gallery__item--14 {
    grid-row: 6 / span 2;
    grid-column: 8 / -1; }
  .gallery__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block; }

.header {
  background-color: #54483a;
  grid-column: full-start / col-end 6;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(16, 29, 44, 0.93)), to(rgba(16, 29, 44, 0.93))), url(/static/media/hero.d11b666f.jpeg);
  background-image: -webkit-linear-gradient(rgba(16, 29, 44, 0.93), rgba(16, 29, 44, 0.93)), url(/static/media/hero.d11b666f.jpeg);
  background-image: linear-gradient(rgba(16, 29, 44, 0.93), rgba(16, 29, 44, 0.93)), url(/static/media/hero.d11b666f.jpeg);
  background-size: cover;
  background-position: center;
  padding: 8rem;
  padding-top: 4rem;
  display: grid;
  grid-template-rows: 1fr -webkit-min-content minmax(6rem, -webkit-min-content) 1fr;
  grid-template-rows: 1fr min-content minmax(6rem, min-content) 1fr;
  grid-template-columns: minmax(-webkit-min-content, -webkit-max-content);
  grid-template-columns: minmax(min-content, max-content);
  grid-row-gap: 1.5rem;
  justify-content: center; }
  @media only screen and (max-width: 50em) {
    .header {
      grid-column: 1 / -1; } }
  @media only screen and (max-width: 37.5em) {
    .header {
      padding: 5rem; } }
  .header__logo {
    height: 3rem;
    justify-self: center; }
  .header__btn {
    align-self: start;
    justify-self: start; }
    @media only screen and (max-width: 425px) {
      .header__btn {
        display: none; } }
  .header__seenon-text {
    display: grid;
    grid-template-columns: 1fr -webkit-max-content 1fr;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 1.5rem;
    align-items: center;
    font-size: 1.6rem;
    color: #aaa; }
    @media only screen and (max-width: 1400px) {
      .header__seenon-text {
        display: none; } }
    @media only screen and (max-width: 425px) {
      .header__seenon-text {
        display: none; } }
    .header__seenon-text::before, .header__seenon-text::after {
      content: "";
      height: 1px;
      display: block;
      background-color: currentColor; }
  .header__seenon-logos {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 3rem;
    justify-items: center;
    align-items: center; }
    @media only screen and (max-width: 1400px) {
      .header__seenon-logos {
        display: none; } }
    @media only screen and (max-width: 425px) {
      .header__seenon-logos {
        display: none; } }
    .header__seenon-logos img {
      max-height: 2.5rem;
      max-width: 100%;
      -webkit-filter: brightness(70%);
              filter: brightness(70%); }

.realtors {
  background-color: #101d2c;
  grid-column: col-start 7 / full-end;
  padding: 3rem;
  display: grid;
  align-content: center;
  justify-content: center;
  justify-items: center;
  grid-row-gap: 2rem; }
  @media only screen and (max-width: 50em) {
    .realtors {
      grid-column: 1 / -1; } }
  .realtors__list {
    display: grid;
    grid-template-columns: -webkit-min-content -webkit-max-content;
    grid-template-columns: min-content max-content;
    grid-column-gap: 2rem;
    grid-row-gap: 5vh;
    align-items: center; }
    @media only screen and (max-width: 50em) {
      .realtors__list {
        grid-template-columns: repeat(3, -webkit-min-content -webkit-max-content);
        grid-template-columns: repeat(3, min-content max-content); } }
    @media only screen and (max-width: 37.5em) {
      .realtors__list {
        grid-template-columns: -webkit-min-content -webkit-max-content;
        grid-template-columns: min-content max-content; } }
  .realtors .realtors__img {
    width: 5rem;
    border-radius: 50%;
    display: block;
    font-size: 62.5%;
    margin: 30px; }
  .realtors__sold {
    text-transform: uppercase;
    color: #aaa;
    margin-top: -3px; }

.sidebar {
  background-color: #c69963;
  grid-column: sidebar-start / sidebar-end;
  grid-row: 1 / -1;
  display: flex;
  justify-content: center; }
  @media only screen and (max-width: 62.5em) {
    .sidebar {
      grid-column: 1 / -1;
      grid-row: 1 / 2;
      justify-content: flex-end;
      align-items: center; } }

.nav-btn {
  border: none;
  border-radius: 4000px;
  background-color: #fff;
  height: 4px;
  width: 4.5rem;
  margin-top: 4rem; }
  .nav-btn::before, .nav-btn::after {
    background-color: #fff;
    height: 4px;
    width: 4.5rem;
    content: "";
    display: block;
    border-radius: 4000px; }
  .nav-btn::before {
    -webkit-transform: translateY(-1.5rem);
            transform: translateY(-1.5rem);
    border-radius: 4000px; }
  .nav-btn::after {
    -webkit-transform: translateY(1.3rem);
            transform: translateY(1.3rem);
    border-radius: 4000px; }
    .nav-btn::after:hover {
      background: #101d2c;
      cursor: pointer; }
  @media only screen and (max-width: 62.5em) {
    .nav-btn {
      margin-top: 0;
      margin-right: 3rem; }
      .nav-btn::before {
        -webkit-transform: translateY(-1.2rem);
                transform: translateY(-1.2rem); }
      .nav-btn::after {
        -webkit-transform: translateY(1rem);
                transform: translateY(1rem); } }

.story__pictures {
  background-color: #c69963;
  grid-column: full-start / col-end 4;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(198, 153, 99, 0.5)), to(rgba(198, 153, 99, 0.5))), url(/static/media/back.f250e126.jpg);
  background-image: -webkit-linear-gradient(rgba(198, 153, 99, 0.5), rgba(198, 153, 99, 0.5)), url(/static/media/back.f250e126.jpg);
  background-image: linear-gradient(rgba(198, 153, 99, 0.5), rgba(198, 153, 99, 0.5)), url(/static/media/back.f250e126.jpg);
  background-size: cover;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(6, 1fr);
  align-items: center; }
  @media only screen and (max-width: 50em) {
    .story__pictures {
      grid-column: 1 / -1;
      padding: 6rem; } }

.story__img--1 {
  width: 100%;
  grid-row: 2 / 6;
  grid-column: 2 / 6;
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.1); }
  @media only screen and (max-width: 50em) {
    .story__img--1 {
      grid-column: 1 / 5;
      grid-row: 1 / -1; } }

.story__img--2 {
  width: 115%;
  grid-row: 4 / 6;
  grid-column: 4 / 7;
  z-index: 20;
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.2); }
  @media only screen and (max-width: 50em) {
    .story__img--2 {
      grid-row: 1 / -1;
      width: 100%; } }

.story__content {
  background-color: #f9f7f6;
  grid-column: col-start 5 / full-end;
  padding: 6rem 8vw;
  display: grid;
  align-content: center;
  justify-items: start; }
  .story__content .btn {
    background-color: #c69963;
    color: #fff;
    border: none;
    border-radius: 0;
    font-family: "Josefin Sans", sans-serif;
    font-size: 1.5rem;
    text-transform: uppercase;
    padding: 1.8rem 3rem;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
  @media only screen and (max-width: 50em) {
    .story__content {
      grid-column: 1 / -1;
      grid-row: 5 / 6; } }

.story__text {
  font-size: 1.5rem;
  font-style: italic;
  margin-bottom: 4rem; }

.heading-1, .heading-2, .heading-3, .heading-4 {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 400; }

.heading-1 {
  font-size: 4.5rem;
  color: #f9f7f6;
  line-height: 1;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }

.heading-2 {
  font-size: 4rem;
  font-style: italic;
  line-height: 1; }
  .heading-2--light {
    color: #f9f7f6; }
  .heading-2--dark {
    color: #54483a; }

.heading-3 {
  font-size: 1.6rem;
  color: #c69963;
  text-transform: uppercase; }

.heading-4 {
  font-size: 1.9rem;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
  .heading-4--light {
    color: #f9f7f6; }
  .heading-4--dark {
    color: #54483a; }

#find {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  #find:hover {
    background-color: #b28451; }

.header .btn {
  background-color: #c69963;
  color: #fff;
  border: none;
  border-radius: 0;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 1.8rem 3rem;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .header .btn:hover {
    background-color: #b28451; }

.mb-sm {
  margin-bottom: 2rem; }

.mb-md {
  margin-bottom: 3rem; }

.mb-lg {
  margin-bottom: 4rem; }

.mb-hg {
  margin-bottom: 8rem; }

@media only screen and (min-width: 1800px) {
  .header__logo {
    height: 6rem; } }

.group {
  position: relative;
  margin: 45px 0; }
  .group .form-input {
    background: none;
    background-color: white;
    color: grey;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid grey;
    margin: 25px 0; }
    .group .form-input:focus {
      outline: none; }
    .group .form-input:focus ~ .form-input-label {
      top: -14px;
      font-size: 12px;
      color: black; }
  .group input[type="password"] {
    letter-spacing: 0.3em; }
  .group .form-input-label {
    color: grey;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    -webkit-transition: 300ms ease all;
    transition: 300ms ease all; }
    .group .form-input-label.shrink {
      top: -14px;
      font-size: 12px;
      color: black; }

.sign-in-and-sign-up {
  width: 850px;
  display: flex;
  justify-content: space-between;
  margin: 30px auto; }
  @media screen and (max-width: 850px) {
    .sign-in-and-sign-up {
      width: 100%;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto; } }
  .sign-in-and-sign-up .title {
    margin: 10px 0;
    font-family: "Roboto";
    font-weight: bold;
    font-size: 1.7rem;
    color: #000; }
  .sign-in-and-sign-up .sub-title {
    padding-top: 1rem;
    color: #54483a; }

.sign-in {
  width: 380px;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 850px) {
    .sign-in {
      margin-top: 3rem; } }
  @media screen and (max-width: 450px) {
    .sign-in {
      padding: 2rem; } }
  @media only screen and (max-width: 320px) {
    .sign-in {
      width: 320px; } }
  .sign-in .title {
    margin: 10px 0; }
  .sign-in .buttons {
    display: flex;
    justify-content: space-between; }

.sign-up {
  display: flex;
  flex-direction: column;
  width: 380px; }
  @media screen and (max-width: 850px) {
    .sign-up {
      margin-top: 3.5rem;
      margin-bottom: 4rem; } }
  @media screen and (max-width: 450px) {
    .sign-up {
      padding: 2rem; } }
  @media only screen and (max-width: 320px) {
    .sign-up {
      width: 320px; } }
  .sign-up .title {
    margin: 10px 0; }

.custom-button,
.google-button {
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  color: white;
  text-transform: uppercase;
  font-family: "Nunito", sans-serif;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  background: black; }
  .custom-button:hover,
  .google-button:hover {
    background-color: white;
    color: black;
    border: 1px solid black; }

.google-button {
  background: #1565c0; }

