@import "./_variables.scss";

.sign-in-and-sign-up {
  width: 850px;
  display: flex;
  justify-content: space-between;
  margin: 30px auto;
  @media screen and (max-width: 850px) {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .title {
    margin: 10px 0;
    font-family: "Roboto";
    font-weight: bold;
    font-size: 1.7rem;
    color: #000;
  }
  .sub-title {
    padding-top: 1rem;
    color: $color-grey-dark-1;
  }
}

.sign-in {
  width: 380px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 850px) {
    margin-top: 3rem;
  }
  @media screen and (max-width: 450px) {
    padding: 2rem;
  }
  @media only screen and (max-width: 320px) {
    width: 320px;
  }
  .title {
    margin: 10px 0;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }
}

.sign-up {
  display: flex;
  flex-direction: column;
  width: 380px;
  @media screen and (max-width: 850px) {
    margin-top: 3.5rem;
    margin-bottom: 4rem;
  }
  @media screen and (max-width: 450px) {
    padding: 2rem;
  }
  @media only screen and (max-width: 320px) {
    width: 320px;
  }
  .title {
    margin: 10px 0;
  }
}

.custom-button,
.google-button {
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  color: white;
  text-transform: uppercase;
  font-family: $font-primary;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  background: black;
  &:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
  }
}

.google-button {
  background: #1565c0;
}
