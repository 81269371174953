@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
$timing        : 265ms;
$iconColor     : #ff003c;
$accent        : #c648c8;
$grape         : #7b00e0;
$orchid        : #ae31d9;

@mixin transformScale($size: 1) {
    transform: scale($size);
    -ms-transform: scale($size);
    -webkit-transform: scale($size);
}

.about-us {
    .form {
        display: flex;
        padding-top: 4rem;
        max-width: 1500px;
        margin: 0 auto;
        margin-bottom: 7rem;
        @media only screen and (max-width: 850px) {
            flex-direction: column;
        }
        .contact-form {
            ::-webkit-input-placeholder{color: #777;}
            :-moz-placeholder { /* Firefox 18- */ color: #777;}
            ::-moz-placeholder {  /* Firefox 19+ */ color: #777;}
            :-ms-input-placeholder {color: #777;}
            width: 60%;
            padding: 2rem;
            @media only screen and (max-width: 850px) {
                width: 100%;
            }
            .get-in-touch {
                padding-left: 1rem;
                font-size: 4rem;
                padding-bottom: 2rem;
                @media only screen and (max-width: 850px) {
                    font-size: 3.6rem;
                    text-align: center;
                }
                 @media only screen and (max-width: 450px) {
                    padding-left: 0px;
                }
            }
            .first-container {
                position: relative;
                width: 100%;
                padding: 1em;
                margin: auto;
                margin-top: 35px;
            }
            .first-container h2{
                text-align: center;
                text-transform: uppercase;
                color: #f55;
                justify-content: flex-end;
                display: flex;
            }
            #contact input[type="text"],#contact input[type="email"], #contact textarea{
                display: block;
                padding: 10px;
                margin: 10px auto;
                width: 100%;
                font-size: 14px;
                font-weight: 400;
                background-color: #f6f6f6;
                border: none;
                -webkit-border-radius: 5px 5px 5px 5px;
                border-radius: 5px 5px 5px 5px;
                transition: all .2s ease-in;
            }
            #contact textarea{
                font-size: 16px;
            }

            #contact input:hover, #contact textarea:hover{
                border-bottom: 2px solid #5fbae9;
                background: white;
            }
            #contact input:focus, #contact textarea:focus{
                outline: none;
                background: white;
                border-bottom: 2px solid #5fbae9;
            }
            #contact textarea{
                max-width: 100%;
                min-width: 100%;
                max-height: 180px;
                min-height: 180px;
            resize: none;
            }
            #contact .send-button{
                text-align: center;
                width: 169px;
                padding-top: 1rem;
                font-size: 12px;
            }
            #contact .send-button button[type="submit"]{
                color: #fff;
                background: #e74c3c;
                border: none;
                width: 100%;
                padding: 10px 0;
                font-size: 20px;
                text-transform: uppercase;
                transition: .5s all ease;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 1px;
                border-radius: 3px;
            cursor: pointer;
            }
            .required-fields {
                font-size: 12px;
            }
            #contact .send-button button[type="submit"]:hover{
                background: #c0392b;
            }

            #contact .send-button button[type="submit"]:focus{
                position: relative;
                top: 2px;
            }
            .line {
                height: 0.5px;
                width: 100%;
                background: #F1F1F1;
                margin: 0 auto;
                display: block;
            }
            @media screen and (min-width: 640px){
                .container{
                    width: 600px;
                }
            
            #contact textarea{
                min-height: 152px;
                margin-right: 0;
            }

                #contact .right,#contact .left{
                    display: block;
                }
                #contact .right{
                    width: 100%;
                }
                #contact .left{
                    display: flex;
                    justify-content: space-between;
                    div {
                        width: 47%;
                    }
                }
                .cl{
                clear: both;
            }
            }
        }
        .contact-form-info {
                padding: 2rem;
                line-height: 1.8;
                .social-container {
                    text-align: center;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 1rem;
                    margin-bottom: 23px;
                    @media only screen and (max-width: 850px) {
                        flex-direction: column;
                    }
                }
                .top-title {
                    margin-top: 39px;
                }
                h3, .top-title {
                    font-size: 2rem;
                }
                p {
                    color: #808080;
                    color: #808080;
                    font-weight: 100;
                   font-size: 15px;
                }
                #line {
                    height: 1px;
                    width: 100%;
                    background: #F1F1F1;
                    margin: 0 auto;
                    display: block;
                }
                .line {
                    height: 0.5px;
                    width: 100%;
                    background: #F1F1F1;
                    margin: 0 auto;
                    display: block;
                }
                .social-icons {
                    list-style: none;
                    li {
                        display: inline-block; 
                        margin: 0.15em;
                        position: relative;
                        font-size: 1.2em;
                        margin-left: 12px;
                    }
                    i {
                        color: #fff;
                        position: absolute;
                        top: 12px;
                        left: 11px;
                        transition: all $timing ease-out;
                    }
                    a {
                        display: inline-block;
                    
                        &:before {
                            @include transformScale();
                            content: " ";
                            width: 40px;
                            height: 40px;
                            border-radius: 100%;
                            display: block;
                            background: linear-gradient(45deg, $iconColor, $accent);
                            transition: all $timing ease-out;
                        }
                        
                        &:hover:before {
                            transform: scale(0);
                            transition: all $timing ease-in;
                        }
                        
                        &:hover i {
                            @include transformScale(2.2);
                            color: $iconColor;
                            background: -webkit-linear-gradient(45deg, $iconColor, $accent);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            transition: all $timing ease-in;
                        }
                    }
                
                    a.social-square {
                    
                        &:before {
                            background: linear-gradient(45deg, $grape, $orchid);
                            border-radius: 10%;
                        }
                    
                        &:hover:before {
                            transform: rotate(-180deg);
                            -ms-transform: rotate(-180deg);
                            -webkit-transform: scale(-180deg);
                            border-radius: 100%;
                        }
                        
                        &:hover i {
                            @include transformScale(1.6);
                            color: #fff;
                            transform: scale(1.6);
                            -webkit-text-fill-color: #fff;
                        }
                    }
                } 
            }
        }
    .map {
        height: 400px;
        margin-bottom: 7rem;
        @media only screen and (max-width: 650px) {
            margin-bottom: 3rem;
        }
    }
    .contact {
        display: flex;
        flex-direction: row;
        padding-top: 4rem;
        padding-bottom: 4rem;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        line-height: 2;
        max-width: 1000px;
        justify-content: space-between;
        margin-bottom: 5rem;
        @media only screen and (max-width: 1100px) {
            padding: 2rem;
        }
        @media only screen and (max-width: 850px) {
            flex-wrap: wrap;
            margin: 0 auto;
            max-width: 600px;
        }
        @media only screen and (max-width: 650px) {
            width: 100%;
            flex-direction: column;
        }
        .contact-info {
            //margin-left: 4rem;
            @media only screen and (max-width: 850px) {
                width: 50%;
                padding-bottom: 2rem;
            }
            h2 {
                color: #808080;
                font-weight: 100;
                letter-spacing: 2px;
            }
            h1 {
                letter-spacing: 1px;
                font-size: 14px;
            }
        }
    }
    footer {
        background-color: #f1f2f6;
        border-style: solid; 
        border-width: 40px 40px 40px; 
        border-color:  #f1f2f6;
        -moz-border-image: url('http://news.unchealthcare.org/images/backgrounds/paper.jpg') 40 40 40 repeat; 
        -webkit-border-image: url('http://news.unchealthcare.org/images/backgrounds/paper.jpg') 40 40 40 repeat; 
        -o-border-image: url('http://news.unchealthcare.org/images/backgrounds/paper.jpg') 40 40 40 repeat; 
        border-image: url('http://news.unchealthcare.org/images/backgrounds/paper.jpg') 40 40 40 repeat;
        display: flex;
        justify-content: space-between;
        padding-top: 1rem;
        padding-bottom: 1rem;
        @media only screen and (max-width: 1100px) {
            padding-top: 4rem;
        }
        @media only screen and (max-width: 860px) {
            display: flex;
            flex-direction: column;
        }
        .social-media {
            display: flex;
            width: 437px;
            justify-content: space-between;
            @media only screen and (max-width: 860px) {
                margin: 0 auto;
                padding-bottom: 24px;
            }
            @media only screen and (max-width: 530px) {
                display: none;
            }
            i {
                color: #e74c3c;
                padding-right: 8px;
            }
            span {
                color: #495057;
                font-size: 15px;
            }
        }
        .all-rights {
            font-size: 13px;
            line-height: 1.4;
            color: #495057;
            @media only screen and (max-width: 860px) {
                margin: 0 auto;
                line-height: 3;
            }
        }
    }
}

.fa-twitter {
    animation-delay: 0.2s;
}
.fa-linkedin {
    animation-delay: 0.4s;
}
.fa-codepen {
    animation-delay: 0.6s;
}
