@import "./variables.scss";

.homes {
  grid-column: center-start / center-end;
  margin: 15rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 7rem;
  padding: 0;
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  margin: 1rem;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  justify-self: center;
  z-index: 100;
  width: 450px;
  height: 500px;
}

.svg-container {
  padding: 10px;
  position: absolute;
  right: 40px;
  top: 40px;
  &:hover {
    cursor: pointer;
    border-top: 1px solid #51cbee;
    border-left: 1px solid #51cbee;
  }
  @media only screen and (max-width: 600px) {
    right: 5%;
    top: 2%;
  }
}

i.icon.heart {
  color: $color-primary;
  width: 2rem;
  margin-right: 1rem;
  outline: none;
  font-size: 1rem;
}
.home {
  background: $color-grey-light-1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 3.5rem;
  &__img {
    width: 100%;
    grid-row: 1 / 2;
    grid-column: 1 / -1;
    z-index: 1;
  }

  &__like {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    fill: $color-primary;
    height: 2.5rem;
    width: 2.5rem;
    z-index: 2;
    justify-self: end;
    margin: 1rem;
  }

  &__name {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
    justify-self: center;
    align-self: end;
    z-index: 3;

    width: 80%;
    font-family: $font-display;
    font-size: 0.8rem;
    text-align: center;
    padding: 1.25rem;
    background-color: $color-secondary;
    color: #fff;
    font-weight: 400;
    transform: translateY(
      50%
    ); /*
      @media only screen and (min-width: 1800px) {
        font-size: 5rem;
      }*/
  }

  &__location,
  &__rooms {
    margin-top: 2.5rem;
  }

  &__location,
  &__rooms,
  &__area,
  &__price {
    font-size: 0.8rem;
    margin-left: 2rem;

    display: flex;
    align-items: center;
    i.icon,
    i.icons,
    i.icon.heart {
      color: $color-primary;
      width: 2rem;
      margin-right: 1rem;
      outline: none;
      font-size: 1rem;
    }
  }
  .home-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100;
    height: 100vh;
  }

  &__btn {
    grid-column: 1 / -1;
    background-color: #c69963;
    color: #fff;
    border: none;
    border-radius: 0;
    font-family: "Josefin Sans", sans-serif;
    font-size: 1.5rem;
    text-transform: uppercase;
    padding: 1.8rem 3rem;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    font-weight: 300;
    line-height: 1.6;
    font-size: 62.5%;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    .contact {
      margin-left: 35%;
    }
    &:hover {
      background-color: #b28451;
      font-size: 62.5%;
    }
  }
}
