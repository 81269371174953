@import './variables';

#header { 
  background: #101d2c;
  color: white;
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-family: 'Roboto';
  height: 96px;
  align-items: center;
  display: flex;
  z-index: 200000;
    .logo{
      height: 3rem;
      margin-left: 20px;
      width: 9rem;
    }
    nav{
      margin-right: 20px;
    }
}
.search {
  &::placeholder {
    color: #dadde2;
    font-size: 1.8rem;
    font-family: $font-primary;
  }
}

#nav-icon1 {
  width: 60px;
  height: 45px;
  position: relative;
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  @media only screen and (max-width: 425px) {
    margin: 0;
  }
}

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
  background: $color-primary;
}

#nav-icon1 span:nth-child(2) {
  top: 18px;
  background: $color-primary;
}

#nav-icon1 span:nth-child(3) {
  top: 36px;
  background: $color-primary;
}

#nav-icon1.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon1.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}




